<template>
    <b-card title="Edit Blogs">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>

                    <!-- Category -->
                    <b-col cols="12">
                        <b-form-group label="Category" label-for="Category">
                            <validation-provider name="Category" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>
                                    <!-- <b-form-select name="category" v-model="item.category" :options="categoriOptions" multiple :select-size="4"></b-form-select>
                                    <div class="mt-3">Selected: <strong>{{ selected }}</strong></div> -->
                                    <select multiple="true" name="category" v-model="item.category" class="form-control">
                                        <option v-for="option in categoriOptions" v-bind:key="option.name"
                                            v-bind:value="option.id">
                                            {{ option.name }}
                                        </option>
                                    </select>
                                    <!-- <select multiple="true" name="category" v-model="category" class="form-control">
                                        <option v-for="option in categoriOptions" v-bind:key="option.name"
                                            v-bind:value="option.id">
                                            {{ option.name }}
                                        </option>
                                    </select> -->
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Title -->
                    <b-col cols="12">
                        <b-form-group label="Title" label-for="title">
                            <validation-provider name="title" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="title" v-model="item.title" :state="errors.length > 0 ? false : null"
                                        placeholder="Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Add to intro insider -->
                    <b-col cols="12">
                        <b-form-group label="Add to intro insider" label-for="checkedNames">
                            <b-form-checkbox id="checkedNames" v-model="item.blogshowoption" name="checkedNames" value="1"
                                unchecked-value="0">
                                Add to intro insider
                            </b-form-checkbox>
                        </b-form-group>
                    </b-col>

                    <!--Short Description -->
                    <b-col cols="12">
                        <b-form-group label="Short Description" label-for="Short description">
                            <validation-provider name="short_description" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>
                                    <b-form-textarea id="short_description" v-model="item.short_description"
                                        :state="errors.length > 0 ? false : null" placeholder="Enter something..." rows="5"
                                        size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Description -->
                    <b-col cols="12">
                        <b-form-group label="Description" label-for="description">
                            <validation-provider name="description" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <ckeditor :editor="editor" :config="editorConfig" id="description"
                                        v-model="item.description" class="form-control form-control-lg"></ckeditor>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="12">
                        <b-img thumbnail fluid :src="previewImg" :class="previewImg.length <= 0 ? 'd-none' : null"
                            alt="Image"></b-img>
                    </b-col>

                    <!-- Profile Image -->
                    <b-col cols="12">
                        <b-form-group label="Profile Image" label-for="profile">
                            <validation-provider name="Profile Image" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="ImageIcon" />
                                    </b-input-group-prepend>
                                    <b-form-file id="profile" v-model="profileImg" :state="errors.length > 0 ? false : null"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here..." />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Title -->
                    <b-col cols="12">
                        <b-form-group label="Meta Title" label-for="meta title">
                            <validation-provider name="meta_title" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_title" v-model="item.meta_title"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Title" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Description -->
                    <b-col cols="12">
                        <b-form-group label="Meta Description" label-for="meta description">
                            <validation-provider name="meta_description" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="BoxIcon" />
                                    </b-input-group-prepend>

                                    <b-form-textarea id="meta_description" v-model="item.meta_description"
                                        placeholder="Enter something..." rows="5" size="lg" max-rows="10"></b-form-textarea>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Meta Keyword -->
                    <b-col cols="12">
                        <b-form-group label="Meta Keyword" label-for="meta Keyword">
                            <validation-provider name="meta_keywords" rules="required" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="meta_keywords" v-model="item.meta_keywords"
                                        :state="errors.length > 0 ? false : null" placeholder="Meta Keyword" />
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>


                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" @click.prevent="validationForm"
                            variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
    required,
} from '@validations'
export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            item: {},
            selected: '',
            checkedNames: "",
            blogshowoption: '',
            options: [],
            categoriOptions: [],
            previewImg: '',
            profileImg: '',
            selectedoptions: null,
            selectedCountry: null,
            options: [
                // {'value': null, text: 'Please select Public / Private'},
                { 'value': 1, 'text': 'Public' },
                { 'value': 0, 'text': 'Private' }
            ],
            countryOptions: [
                { 'value': null, text: 'Please select a country' },
            ],
            editor: ClassicEditor,
            editorData: '<p>Content of the editor.</p>',
            editorConfig: {
                // The configuration of the editor.

            }
        }
    },
    created() {
        this.getItem();
        // this.getCountries();
        this.getCategories();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        addFind: function () {
            this.finds.push({ value: '' });
        },
        deleteFind: function (index) {
            console.log(index);
            console.log(this.finds);
            this.finds.splice(index, 1);
        },
        reset() {
            this.title = '',
                this.description = '',
                this.previewImg = '',
                this.price = '',
                this.checkedNames = '',
                this.blogshowoption = '',
                this.discount = '',
                this.vat = '',
                this.tag_line = '',
                this.tag = '',
                this.meta_title = '',
                this.meta_description = '',
                this.meta_keywords = '',
                this.profileImg = '',
                this.selectedoptions = null,
                this.selectedCountry = null;
        },
        getItem() {
            store.dispatch('app/commitActivateLoader');
            var id = this.$route.params.id;

            db.collection('blogs').doc(id).get().then((doc) => {
                console.log(doc.data());
                this.item = doc.data();
                this.previewImg = doc.data().image;
            })
            store.dispatch('app/commitDeactivateLoader');
        },
        getCategories() {
            this.categoriOptions = [];

            db.collection("categoriesblog")
                // .where("type", "==", '1')
                .get()
                .then((querySnapshot) => {
                    //console.log('hhhh1');
                    querySnapshot.forEach((doc) => {
                        if (doc.data().type == 1) {
                            this.categoriOptions.push({
                                id: doc.id,
                                name: doc.data().name,
                            });
                        }
                        console.log(doc.id, " => ", doc.data());

                    });

                    // console.log(this.categoriOptions);
                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
        },

        // getCountries() {
        //     this.countries = [];
        //     db.collection("countries")
        //         .get()
        //         .then((querySnapshot) => {
        //             //console.log('hhhh1');
        //             querySnapshot.forEach((doc) => {
        //                 this.countryOptions.push({
        //                     value: doc.id,
        //                     text: doc.data().country,
        //                     slug: doc.data().slug,
        //                 });
        //                 // console.log(doc.id, " => ", doc.data());
        //             });
        //         })
        //         .catch((error) => {
        //             console.log("Error getting documents: ", error);
        //         });
        // },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },
        submit() {
            store.dispatch('app/commitActivateLoader');
            if (this.blogshowoption == true) {
                this.blogshowoption = 1;
            } else if (this.blogshowoption == false) {
                this.blogshowoption = 0;
            }
            // alert(this.blogshowoption)
            var id = this.$route.params.id;
            var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
            db.collection("blogs")
                .doc(id)
                .update({
                    category: this.item.category,
                    title: this.item.title,
                    // image: this.item.previewImage,
                    type: this.item.type,
                    blogshowoption: this.item.blogshowoption,
                    short_description: this.item.short_description,
                    description: this.item.description,
                    meta_title: this.item.meta_title,
                    meta_description: this.item.meta_description,
                    meta_keywords: this.item.meta_keywords,
                    created: date,
                    modified: date,
                })
                .then((doc) => {
                    console.log("Added Successfully!");
                    const filename = this.profileImg.name;
                    if (filename) {
                        const ext = filename.slice(filename.lastIndexOf('.'));
                        const storageRef = storage.ref();
                        const fileRef = storageRef.child('blogs/' + doc.id + "." + ext);
                        fileRef.put(this.profileImg).then(result => {
                            fileRef.getDownloadURL().then(imageUrl => {
                                console.log(imageUrl);
                                db.collection("blogs").doc(doc.id).update({ image: imageUrl })
                                store.dispatch('app/commitDeactivateLoader');
                                this.$router.push({ name: 'blogs-list' });
                                this.showNotification('Category Updated Successfully!', 'AlignJustifyIcon', 'success');
                            }).catch((error) => {
                                console.error("Error writing document: ", error);
                                this.showNotification('Error! Request Failed!', 'AlignJustifyIcon', 'danger');
                            });
                        });
                    }
                    this.showNotification('Category Updated Successfully!', 'AlignJustifyIcon', 'success');
                    this.$router.push({ name: 'blogs-list' });
                })
            // var id = localStorage.getItem("userSessionId");
            // console.log('rrr ' + id);
        },
        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
    },
}
</script>